// Escapes regex characters from a string so they are treated regularly
const specialRegexCharacters = /([[\\^$.|?*+()\]])/g;
export const escapeRegex = input =>
    input.replace(specialRegexCharacters, '\\$1');

// Converts input string to lower case, removes spaces and underscores
export const toUniformString = input =>
    input.toLowerCase().replace(/[ _]/g, '');

// A default option filtering function compatible with the Ant Design Select Component.
export const filterOption = (v, option) =>
    // The display label of each select option comes in as `option.children`
    option.children?.toLowerCase().search(escapeRegex(v.toLowerCase())) > -1;

// Based on https://stackoverflow.com/a/13627586
export const ordinal = i => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + 'st';
    }
    if (j === 2 && k !== 12) {
        return i + 'nd';
    }
    if (j === 3 && k !== 13) {
        return i + 'rd';
    }
    return i + 'th';
};

export const capitalize = input =>
    `${input.charAt(0).toUpperCase()}${input.slice(1)}`;

export const standardizeStringCase = input =>
    typeof input == 'string' ? capitalize(input.toLowerCase()) : input;
