// react-hot-loader needs to be included before React
import { hot } from 'react-hot-loader/root';
import { useSelector } from 'react-redux';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Alert, Spin } from 'antd';
import './fontAwesome.js';

import { userCanBulkUpload } from './util/projectHelper.js';

import ObservationHome from './components/ObservationHome';
import BulkUpload from './components/BulkUpload.jsx';
import AuthLoginForm from './components/AuthLoginForm';
import AuthPasswordResetRequestForm from './components/AuthPasswordResetRequestForm';
import AuthPasswordResetChangeForm from './components/AuthPasswordResetChangeForm';
import AuthRegistrationComplete from './components/AuthRegistrationComplete';
import AuthRegistrationForm from './components/AuthRegistrationForm';
import FormCard from './components/FormCard';
import { useInitialize } from './util/useInitialize';
import LayoutBasic from './components/LayoutBasic';
import LayoutSection from './components/LayoutSection';
import Home from './components/Home';
import Manage from './components/Manage';
import FourOhFour from './components/404';
import OverlaySpinner from './components/OverlaySpinner';
import ErrorBoundary from './components/ErrorBoundary';
import PrivateRoute from './components/PrivateRoute';
import UserProfileForm from './components/UserProfileForm.jsx';
import DownloadTray from './components/DownloadTray.jsx';
import ObservationFormWrapper from './components/ObservationFormWrapper.jsx';

const LazyVisualizationsHome = React.lazy(() =>
    import('./components/VisualizationsHome')
);
const LazyVisualizationsView = React.lazy(() =>
    import('./components/VisualizationsView')
);
const LazyVisualizationsEdit = React.lazy(() =>
    import('./components/VisualizationsEdit')
);

const Lazy100kVisualizationsHome = React.lazy(() =>
    import('./components/100kVisualizationsHome')
);
const Lazy100kObservationHome = React.lazy(() =>
    import('./components/100kObservationHome')
);

function App() {
    const project = useSelector(state => state.project);

    // Initialize the project, schema, and authentication status of the app
    useInitialize();

    if (project?.error) {
        return <Alert message='Failed to load project' type='error' />;
    }

    return (
        <div className='App'>
            <ErrorBoundary>
                <Router>
                    <LayoutBasic label={project?.data?.label}>
                        <DownloadTray />
                        <Switch>
                            <PrivateRoute
                                path='/upload'
                                userIsAllowed={userCanBulkUpload}
                            >
                                <LayoutSection padding='80px 0'>
                                    <FormCard title='Bulk Upload'>
                                        <BulkUpload
                                            useStations={
                                                project?.data?.useStations
                                            }
                                        />
                                    </FormCard>
                                </LayoutSection>
                            </PrivateRoute>
                            <PrivateRoute
                                path={[
                                    '/observations/new',
                                    '/observations/:observationId',
                                ]}
                            >
                                <LayoutSection small padding='80px 0'>
                                    <ObservationFormWrapper />
                                </LayoutSection>
                            </PrivateRoute>
                            <PrivateRoute
                                path={[
                                    '/visualizations/new',
                                    '/visualizations/:visualizationId/edit',
                                ]}
                            >
                                <React.Suspense fallback={<Spin />}>
                                    <LazyVisualizationsEdit />
                                </React.Suspense>
                            </PrivateRoute>
                            <PrivateRoute
                                path={'/visualizations/:visualizationId'}
                            >
                                <React.Suspense fallback={<Spin />}>
                                    <LazyVisualizationsView />
                                </React.Suspense>
                            </PrivateRoute>
                            <PrivateRoute path='/visualizations'>
                                <LayoutSection padding='80px 0'>
                                    <React.Suspense fallback={<Spin />}>
                                        <LazyVisualizationsHome />
                                    </React.Suspense>
                                </LayoutSection>
                            </PrivateRoute>
                            <PrivateRoute path='/observations'>
                                <LayoutSection padding='80px 0'>
                                    <ObservationHome />
                                </LayoutSection>
                            </PrivateRoute>
                            <PrivateRoute path={'/profile'}>
                                <LayoutSection small padding='80px 0'>
                                    <FormCard title='Edit user profile'>
                                        <UserProfileForm />
                                    </FormCard>
                                </LayoutSection>
                            </PrivateRoute>
                            <Route path='/login'>
                                <LayoutSection small padding='80px 0'>
                                    <FormCard
                                        title='Sign in'
                                        subtitle={`${project?.data?.label ||
                                            ''} | FieldScope`}
                                    >
                                        <AuthLoginForm />
                                    </FormCard>
                                </LayoutSection>
                            </Route>
                            <Route path='/register/complete'>
                                <LayoutSection small padding='80px 0'>
                                    <FormCard
                                        title={
                                            project?.data?.autoApprove
                                                ? 'Registration Complete'
                                                : 'Account Request Submitted'
                                        }
                                        subtitle={`${project?.data?.label ||
                                            ''} | FieldScope`}
                                    >
                                        <AuthRegistrationComplete />
                                    </FormCard>
                                </LayoutSection>
                            </Route>
                            <Route path='/register'>
                                <LayoutSection small padding='80px 0'>
                                    <FormCard
                                        title='Sign up'
                                        subtitle={`${project?.data?.label ||
                                            ''} | FieldScope`}
                                    >
                                        <AuthRegistrationForm />
                                    </FormCard>
                                </LayoutSection>
                            </Route>
                            <Route path='/reset/:hash'>
                                <LayoutSection small padding='80px 0'>
                                    <FormCard
                                        title='Reset password'
                                        subtitle={`${project?.data?.label ||
                                            ''} | FieldScope`}
                                    >
                                        <AuthPasswordResetChangeForm />
                                    </FormCard>
                                </LayoutSection>
                            </Route>
                            <Route path='/reset'>
                                <LayoutSection small padding='80px 0'>
                                    <FormCard
                                        title='Reset password'
                                        subtitle={`${project?.data?.label ||
                                            ''} | FieldScope`}
                                    >
                                        <AuthPasswordResetRequestForm />
                                    </FormCard>
                                </LayoutSection>
                            </Route>
                            <PrivateRoute path='/manage'>
                                <LayoutSection padding='80px 0'>
                                    <Manage project={project} />
                                </LayoutSection>
                            </PrivateRoute>
                            <PrivateRoute path='/100k/observations'>
                                <LayoutSection padding='80px 0'>
                                    <React.Suspense fallback={<Spin />}>
                                        <Lazy100kObservationHome />
                                    </React.Suspense>
                                </LayoutSection>
                            </PrivateRoute>
                            <PrivateRoute path='/100k/visualizations'>
                                <LayoutSection padding='80px 0'>
                                    <React.Suspense fallback={<Spin />}>
                                        <Lazy100kVisualizationsHome />
                                    </React.Suspense>
                                </LayoutSection>
                            </PrivateRoute>
                            <Route exact path='/'>
                                <Home />
                            </Route>
                            <Route path={['/404', '*']}>
                                <FourOhFour />
                            </Route>
                        </Switch>
                    </LayoutBasic>
                </Router>
                <OverlaySpinner show={project?.fetching} />
            </ErrorBoundary>
        </div>
    );
}

export default hot(App);
