import apiRequest from '../util/apiRequest';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import pluralize from 'pluralize';
import moment from 'moment';
import { find, findIndex, map, forEach } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import update from 'immutability-helper';

import {
    Affix,
    Alert,
    Button,
    Checkbox,
    Form,
    Input,
    List,
    message,
    Modal,
    Spin,
    Typography,
    Space,
    Card,
} from 'antd';

import { getCurrentSchema } from '../util/schemaHelper';
import { userCanAdminProject, userIsLoc } from '../util/projectHelper';

import {
    observationSchemaFolderAddendum,
    makeObservationUrl,
    observationUrl,
    stationIdKey,
    models,
    mediaTypes,
    makePhotoUrl,
    mediaUrl,
    makeMediaUrl,
    getSignedS3MediaUrl,
    antdUploadStatus,
} from '../util/constants';
import {
    getFolderKey,
    getInitialRepeaterValues,
    formDataToObjects,
    flattenObservation,
    createEmptyInitialFormData,
    formatPhotosForUploadComponent,
    normalizeFileObject,
    formatMediaForUploadComponent,
    getFoldersFieldDefs,
} from '../util/observationFormHelper';

import {
    updateStation,
    addUserToStation,
    addStation,
} from '../actions/stations';
import { updateObservations } from '../actions/filterSet';
import { deleteObservation } from '../actions/observations';
import { setRedirectPath } from '../actions/auth';
import { setOnlineStatus } from '../actions/ui';

import SchemaFormFields from './SchemaFormFields';
import ObservationFormStations from './ObservationFormStations';
import ObservationFormComments from './ObservationFormComments';
import ShowIfManagerOrLoc from './ShowIfManagerOrLoc';
import FourOhFour from './404';
import RequiredFormLegend from './RequiredFormLegend';
import ObservationFormStationForm from './ObservationFormStationForm';
import MediaUploadList from './MediaUploadList';
import ObservationFormSection from './ObservationFormSection';
import ObservationFormOwner from './ObservationFormOwner';
import ObservationFormLocation from './ObservationFormLocation';

const { Paragraph, Text, Title } = Typography;

const SHOULD_ADD_ANOTHER = 'SHOULD_ADD_ANOTHER';

function ObservationForm({
    schemas,
    stations,
    isFetchingStations,
    deleteObservationState,
    project,
    isOffline,
    observationEditLocation,
    user,
    dispatch,
}) {
    const schema = getCurrentSchema(schemas);

    const basemap = project?.data?.basemaps[0];
    const [form] = Form.useForm();
    const history = useHistory();
    const returnToObservationList = () => {
        if (observationEditLocation) {
            history.push(observationEditLocation.pathname);
        } else {
            history.push('/observations');
        }
    };

    /* Set up for editing an existing observation

    Only data owners, LOCs, PROs or admins can edit

    TODO: Add edit access to all observations for the various admin types.
    */
    const userId = user?.userId;
    const [savedObservation, setSavedObservation] = useState(null);
    const [uploadedPhotos, setUploadedPhotos] = useState([]);
    /* Unlike photos, media uploads happen in multiple sequential requests.
    Key pieces of information return asynchronously . Ideally there would be
    a single media list in state but for speed of avoiding complex, dense,
    asynchronous updates to a list of nested objects, we store media objects
    from the database vs. media objects from this form in separate lists on
    state. They two lists are rectified on save observation.
    */

    const [photoUploadStatus, setPhotoUploadStatus] = useState(null);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [
        uploadedDocumentsWithFileIds,
        setUploadedDocumentsWithFileIds,
    ] = useState([]);
    const [documentUploadStatus, setDocumentUploadStatus] = useState(null);

    const [uploadedAudio, setUploadedAudio] = useState([]);
    const [uploadedAudioWithFileIds, setUploadedAudioWithFileIds] = useState(
        []
    );
    const [audioUploadStatus, setAudioUploadStatus] = useState(null);

    const [uploadedVideos, setUploadedVideos] = useState([]);
    const [uploadedVideosWithFileIds, setUploadedVideosWithFileIds] = useState(
        []
    );
    const [videoUploadStatus, setVideoUploadStatus] = useState(null);

    // Manage owner data independently from the rest of the observation form
    const [observationOwner, setObservationOwner] = useState(null);

    const [showStationSelect, setShowStationSelect] = useState(false);

    const { observationId } = useParams();

    // Track successive iterations of the ObservationForm
    // We use increments to signal destroying and re-rendering form components like
    // observationFormSections (i.e. such as on saveAndAddAnother) who
    // should be reset to their default display state
    const [formIterationCounter, setCounter] = useState(0);

    // flips to true on save and add another
    const addingAnother = form.getFieldValue(SHOULD_ADD_ANOTHER);

    useEffect(() => {
        // addingAnother becomes false when the form values are reset
        // subsequently, reset the form's display state
        if (!addingAnother) {
            setCounter(counter => counter + 1);
        }
    }, [addingAnother]);

    const [fetchObservationState, fetchObservation] = useAsyncFn(async () => {
        /* EDIT MODE. Fetch the observation by id in the route  */
        try {
            const response = await apiRequest.get(
                makeObservationUrl(observationId)
            );
            const payload = response.data;
            if (payload.status === 200 && payload.result) {
                setSavedObservation(payload.result);
                setUploadedPhotos(
                    formatPhotosForUploadComponent(payload.result.photos)
                );
                const existingFiles = formatMediaForUploadComponent(
                    payload.result.media
                );

                const existingDocuments = existingFiles.filter(
                    f => f.type === mediaTypes.document
                );
                setUploadedDocuments(existingDocuments);
                setUploadedDocumentsWithFileIds(existingDocuments);

                const existingAudio = existingFiles.filter(
                    f => f.type === mediaTypes.audio
                );
                setUploadedAudio(existingAudio);
                setUploadedAudioWithFileIds(existingAudio);

                const existingVideos = existingFiles.filter(
                    f => f.type === mediaTypes.video
                );
                setUploadedVideos(existingVideos);
                setUploadedVideosWithFileIds(existingVideos);

                setObservationOwner(payload.result.owner);

                return payload.result;
            } else if (payload.status === 404) {
                return payload;
            } else {
                throw response.error;
            }
        } catch (e) {
            return e;
        }
    }, [observationId]);

    useEffect(() => {
        /**
        If schema is loaded and contains folders with repeating values,
        initialize them with one value. The output of this transformation
        will be something like:

        {
            "Frog & Toad Observation-fields": [
                {
                    "FrogWatch_SpeciesId": null,
                    "FrogWatch_CallIntensity": null
                }
            ]
        }

        Fired when loading saved observations to initialize repeating fields
        correctly, or for new forms by initializating them to null. Not fired
        if the user has input any values, so we don't overwrite their input.
        The stationId field is used as proxy for checking if a user has input.
        */

        if (schema && (savedObservation || !form.getFieldValue(stationIdKey))) {
            const flattenedSavedObservation =
                savedObservation &&
                flattenObservation(savedObservation, schema);
            form.setFieldsValue(
                getInitialRepeaterValues(schema, flattenedSavedObservation)
            );
        }
    }, [form, schema, savedObservation]);

    useEffect(() => {
        if (!userId) {
            return;
        }
        if (observationId) {
            fetchObservation();
        }
    }, [observationId, fetchObservation, userId]);

    const messageKey = 'saveObservation';

    const observationFieldFolders = observationSchemaFolderAddendum.concat(
        schema?.observation.folders
    );

    // Save new and edited observation(s)
    const [saveObservationsState, saveObservations] = useAsyncFn(
        async data => {
            const createMediaFormData = fileData => {
                const formData = new FormData();
                // Empty fields should be interpreted as an empty string, not undefined
                formData.set('label', fileData.label || '');
                formData.set('description', fileData.description || '');
                return formData;
            };

            for (let i = 0, count = uploadedPhotos.length; i < count; i++) {
                message.loading({
                    content: `Saving ${i + 1} of ${count} photos`,
                    key: messageKey,
                });
                const photoData = normalizeFileObject(uploadedPhotos[i]);
                const photoFormData = createMediaFormData(photoData);
                const url = makePhotoUrl(photoData.photoId);
                try {
                    const response = await apiRequest.post(url, photoFormData);
                    const payload = response.data;
                    if (!payload.result) {
                        throw new Error(payload.error);
                    }
                } catch {
                    message.error(
                        `Failed to update photo ${i + 1} of ${count}`
                    );
                }
            }

            const finalMediaSet = [];
            const updateFinalMediaSet = async (
                items,
                itemsWithFileIds,
                type
            ) => {
                for (let i = 0, count = items.length; i < count; i++) {
                    message.loading({
                        content: `Saving ${i + 1} of ${count}`,
                        key: messageKey,
                    });
                    const data = normalizeFileObject(items[i]);
                    const formData = createMediaFormData(data);

                    const fileWithFileId = itemsWithFileIds.find(
                        file => file.label === data.name
                    );
                    const mediaUrl = makeMediaUrl(fileWithFileId.fileId);

                    finalMediaSet.push({
                        ...data,
                        fileId: fileWithFileId.fileId,
                    });

                    try {
                        const response = await apiRequest.post(
                            mediaUrl,
                            formData
                        );
                        const payload = response.data;
                        if (!payload.result) {
                            throw new Error(payload.error);
                        }
                    } catch {
                        message.error(
                            `Failed to update ${type} ${i + 1} of ${count}`
                        );
                    }
                }
            };

            await updateFinalMediaSet(
                uploadedDocuments,
                uploadedDocumentsWithFileIds,
                mediaTypes.document
            );

            await updateFinalMediaSet(
                uploadedAudio,
                uploadedAudioWithFileIds,
                mediaTypes.audio
            );

            await updateFinalMediaSet(
                uploadedVideos,
                uploadedVideosWithFileIds,
                mediaTypes.video
            );

            const observations = formDataToObjects(schema, data);
            const payloads = [];
            var failureCount = 0;

            for (let i = 0, count = observations.length; i < count; i++) {
                message.loading({
                    content: `Saving ${i + 1} of ${count} observations`,
                    key: messageKey,
                });
                const formData = {};
                if (observationId) {
                    formData[stationIdKey] = savedObservation[stationIdKey];
                }
                for (const [key, value] of Object.entries(observations[i])) {
                    if (!(value == null)) {
                        if (moment.isMoment(value)) {
                            formData[[key]] = value.toString();
                        } else {
                            formData[[key]] = value;
                        }
                    }
                }

                formData['photos'] = JSON.stringify(
                    map(uploadedPhotos, 'photoId')
                );

                formData['media'] = JSON.stringify(
                    map(finalMediaSet, 'fileId')
                );

                // We will need to include the names of the project-specific observation attributes
                // in case the app is offline and the service worker needs these data to construct a response

                formData['attributes'] = getFoldersFieldDefs(
                    observationFieldFolders
                );

                formData['ownerId'] = userId;

                const url = observationId
                    ? makeObservationUrl(observationId)
                    : observationUrl;

                const response = observationId
                    ? await apiRequest.put(url, formData)
                    : await apiRequest.post(url, formData);
                const payload = response.data;
                if (!payload.result) {
                    failureCount += 1;
                }
                payloads.push(payload);
            }
            if (failureCount > 0) {
                message.error({
                    content: `Save failed`,
                    key: messageKey,
                    duration: 2,
                });
            } else {
                message.success({
                    content: `${pluralize(
                        'Observation',
                        payloads.length
                    )} saved`,
                    key: messageKey,
                    duration: 2,
                });
            }
            return { payloads, [SHOULD_ADD_ANOTHER]: data[SHOULD_ADD_ANOTHER] };
        },
        [
            form,
            schema,
            observationId,
            savedObservation,
            uploadedPhotos,
            uploadedDocuments,
            uploadedDocumentsWithFileIds,
            uploadedAudio,
            uploadedAudioWithFileIds,
            uploadedVideos,
            uploadedVideosWithFileIds,
        ]
    );

    useEffect(() => {
        // When an offline request fails, sometimes the status is 'undefined'
        if (
            photoUploadStatus === antdUploadStatus.error ||
            documentUploadStatus === antdUploadStatus.error ||
            audioUploadStatus === antdUploadStatus.error ||
            videoUploadStatus === antdUploadStatus.error ||
            photoUploadStatus === undefined ||
            documentUploadStatus === undefined ||
            audioUploadStatus === undefined ||
            videoUploadStatus === undefined
        ) {
            // Test if the error was caused by being offline
            dispatch(setOnlineStatus());
        }
    }, [
        photoUploadStatus,
        documentUploadStatus,
        audioUploadStatus,
        videoUploadStatus,
        dispatch,
    ]);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    if (
        observationId === deleteObservationState.observation?.observationId &&
        deleteObservationState.succeeded
    ) {
        message.success(`Deleted observation ${observationId}`);
        returnToObservationList();
    }

    // If project has no data schemas, redirect to observations list
    if (project?.data && project.data.dataSchemas?.length === 0) {
        history.push('/observations');
    }

    if (!schema || fetchObservationState.loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px',
                }}
            >
                <Spin size='large' />
            </div>
        );
    }

    if (schema.error) {
        return <Alert message='Failed to load schema' type='error' />;
    }

    if (!userId) {
        return (
            <Paragraph>
                <Link
                    to='/login'
                    onClick={() =>
                        dispatch(setRedirectPath(history.location.pathname))
                    }
                >
                    Log in
                </Link>{' '}
                to view or enter a new observation
            </Paragraph>
        );
    }

    const isOwner = observationOwner?.ownerId === userId;
    const isAdmin = userCanAdminProject(user, project?.data);
    const isLoc = userIsLoc(
        user,
        project?.data,
        fetchObservationState.value?.ownerLocFieldValue
    );
    if (
        (observationId && !isOwner && !isAdmin && !isLoc) ||
        fetchObservationState.value?.status === 404
    ) {
        // Guard observation detail page from unauthorized viewers or cross-project access
        return <FourOhFour />;
    }

    if (
        fetchObservationState.error ||
        (observationId && !fetchObservationState.value)
    ) {
        // For errors other than 404s
        return <Text>Oops, there was an error fetching this observation.</Text>;
    }

    const flattenedObservation = savedObservation
        ? flattenObservation(savedObservation, schema)
        : createEmptyInitialFormData(schema);

    const handleFinish = async values => {
        const result = await saveObservations(
            Object.assign(
                {
                    schema: schema.name,
                },
                { ...values, useStations }
            )
        );
        const observations = result?.payloads
            ?.filter(p => p.status === 200)
            .map(p => p.result);

        let postSaveStationsList = [...stations];

        // on stationless projects, the API silently creates or updates the
        // observation's station. the station is not returned but rather its few
        // dataful fields are returned on the observation object. the frontend
        // expects the stations list in redux to be up-to-date
        // manually update redux with the new data
        if (!useStations) {
            // save or update stationless station data in redux
            observations.forEach(({ stationId, geometry }) => {
                const existingStationInRedux = postSaveStationsList.find(
                    s => s.stationId === stationId
                );
                if (existingStationInRedux) {
                    const updatedStation = {
                        ...existingStationInRedux,
                        geometry,
                    };
                    dispatch(
                        updateStation({
                            station: updatedStation,
                            user,
                            schema,
                            project,
                        })
                    );
                    const idx = findIndex(
                        postSaveStationsList,
                        s => s.stationId === stationId
                    );
                    postSaveStationsList[idx] = updatedStation;
                } else {
                    const newStation = {
                        stationId,
                        observations: [],
                        geometry,
                        attributes: {},
                    };
                    postSaveStationsList.push(newStation);
                    dispatch(addStation({ station: newStation }));
                }
            });
        }

        dispatch(
            updateObservations({
                observations,
                stations: postSaveStationsList,
                user,
                schema,
                project: project?.data,
            })
        );

        // If the user isn't listed on the updated observation's or
        // observations' station's registered users list yet, update on the
        // stations list in redux
        if (observations.length && useStations) {
            const station = postSaveStationsList.find(
                station => station.stationId === observations[0].stationId
            );
            dispatch(addUserToStation({ user, station }));
        }
    };

    const handleFinishFailed = () => {
        message.error('One or more fields are incomplete or invalid');
    };

    const observationFields = observationFieldFolders.map(folder => {
        // Repeating fields result in unique observations
        // Editing an observation should not allow adding repeating fields
        const fieldSet = folder.repeatable ? (
            <Form.List name={getFolderKey(folder)}>
                {(sets, { add, remove }) => {
                    const addButton = !observationId && (
                        <Button
                            type='dashed'
                            onClick={add}
                            style={{
                                width: '100%',
                                height: 'auto',
                                whiteSpace: 'normal',
                            }}
                            icon={
                                <FontAwesomeIcon
                                    icon={['far', 'plus-circle']}
                                />
                            }
                        >
                            Add {folder.label}
                        </Button>
                    );

                    return (
                        <>
                            {sets.map(set => {
                                const removeButton = !observationId && (
                                    <Button
                                        type='link'
                                        onClick={() => {
                                            remove(set.name);
                                        }}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            whiteSpace: 'normal',
                                            marginTop: '5px',
                                        }}
                                        icon={
                                            <FontAwesomeIcon
                                                icon={['far', 'trash-alt']}
                                            />
                                        }
                                    >
                                        Remove {folder.label}
                                    </Button>
                                );

                                return (
                                    <div
                                        className='observation__observed'
                                        key={set.key}
                                    >
                                        <SchemaFormFields
                                            fields={folder.fields}
                                            set={set}
                                            indicateRequired
                                            useExpandingInputs
                                        />
                                        {sets.length > 1 ? removeButton : null}
                                    </div>
                                );
                            })}
                            {addButton}
                        </>
                    );
                }}
            </Form.List>
        ) : (
            <SchemaFormFields
                fields={folder.fields}
                indicateRequired
                useExpandingInputs
            />
        );

        const atLeastOneRequiredField = folder.fields.some(f => !!f.required);
        return (
            <ObservationFormSection
                key={folder.label + formIterationCounter}
                label={folder.label}
                hasRequired={atLeastOneRequiredField}
                defaultIsOpen={atLeastOneRequiredField}
            >
                {fieldSet}
            </ObservationFormSection>
        );
    });

    const handleSaveClick = () => {
        form.setFieldsValue({ [SHOULD_ADD_ANOTHER]: false });
        form.submit();
    };

    const handleSaveAndAddAnotherClick = () => {
        form.setFieldsValue({ [SHOULD_ADD_ANOTHER]: true });
        form.submit();
    };

    const resetForm = () => {
        if (addingAnother) {
            setUploadedDocuments([]);
            setUploadedVideos([]);
            setUploadedPhotos([]);
            setUploadedAudio([]);
        }

        form.resetFields();
        form.setFieldsValue(getInitialRepeaterValues(schema));
        window.scrollTo(0, 0);
    };

    if (!saveObservationsState.loading) {
        if (saveObservationsState.error) {
            message.error({
                content: 'An error prevented saving a new observation.',
                key: messageKey,
            });
        } else if (saveObservationsState.value) {
            const failureCount = saveObservationsState.value.payloads.filter(
                o => o.status !== 200
            ).length;
            if (failureCount === 0) {
                // TODO: Update the station in state - Add user ID
                if (saveObservationsState.value[SHOULD_ADD_ANOTHER]) {
                    // saveObservationsState.value[SHOULD_ADD_ANOTHER] does not reset, so
                    // check the variable derived from form, which does
                    if (addingAnother) {
                        resetForm();
                    }
                } else {
                    returnToObservationList();
                    window.scrollTo(0, 0);
                }
            }
        }
    }

    const uploadInProgress =
        photoUploadStatus === antdUploadStatus.uploading ||
        documentUploadStatus === antdUploadStatus.uploading ||
        audioUploadStatus === antdUploadStatus.uploading ||
        videoUploadStatus === antdUploadStatus.uploading;

    const saveAndAddAnotherButton = !observationId && (
        <Button
            type='primary'
            onClick={handleSaveAndAddAnotherClick}
            disabled={saveObservationsState.loading || uploadInProgress}
            loading={
                form.getFieldValue(SHOULD_ADD_ANOTHER) &&
                saveObservationsState.loading
            }
        >
            Save &amp; add another
        </Button>
    );

    const useStations = project?.data?.useStations;

    const station = find(stations, {
        stationId: fetchObservationState.value?.stationId,
    });

    const isStationLoc = userIsLoc(
        user,
        project?.data,
        station?.ownerLocFieldValue
    );

    const isOfflineDraftObservation = observationId?.includes('draft');
    const isOfflineDraftStation = station?.stationId?.includes('draft');
    const isStationOwner = userId && userId === station?.ownerId;

    const isOnlineWithStationEditAccess =
        (isStationOwner || isAdmin || isStationLoc) && !isOffline;
    const isOfflineWithOwnedDraftStation =
        isOffline && isStationOwner && isOfflineDraftStation;

    const editStationButton = (isOnlineWithStationEditAccess ||
        isOfflineWithOwnedDraftStation) && (
        <ObservationFormStationForm
            schema={schema}
            project={project}
            editStation={station}
            onChooseStation={() => setShowStationSelect(true)}
            onFinish={station => {
                dispatch(
                    updateStation({
                        station,
                        user,
                        schema,
                        project,
                    })
                );
            }}
            basemap={basemap}
            userId={userId}
        />
    );

    const locationSection = !useStations ? (
        <ObservationFormLocation
            project={project}
            editStation={station}
            basemap={basemap}
            isOffline={isOffline}
            form={form}
            isGeoLocationActive={true}
        />
    ) : observationId && !showStationSelect ? (
        <div className='observation__section'>
            <Text className='observation__section--title'>Station</Text>
            <Form.Item>
                {!isFetchingStations ? (
                    <label>
                        Station name <br />
                        <div className='observation__section--editable'>
                            <Card size='small'>{station?.stationName}</Card>
                            {editStationButton}
                        </div>
                    </label>
                ) : (
                    <Spin />
                )}
            </Form.Item>
        </div>
    ) : (
        <>
            <Title type='secondary' level={4} style={{ margin: '10px 0' }}>
                Station *
            </Title>
            <ObservationFormStations
                isFetchingStations={isFetchingStations}
                stations={stations}
                user={user}
                form={form}
                schema={schema}
                project={project}
                basemap={basemap}
                isOffline={isOffline}
            />
        </>
    );

    const metadataSection = observationId && (
        <ShowIfManagerOrLoc
            locFieldValue={fetchObservationState.value?.ownerLocFieldValue}
        >
            <div
                className='observation__section'
                style={{ paddingTop: '26px' }}
            >
                <List split={false}>
                    <List.Item>Observation ID: {observationId}</List.Item>
                    {useStations && (
                        <List.Item>
                            <Space>
                                <Text>
                                    Station ID:{' '}
                                    {fetchObservationState.value?.stationId}
                                </Text>
                            </Space>
                        </List.Item>
                    )}
                </List>
            </div>
        </ShowIfManagerOrLoc>
    );

    const ownerSection = observationId ? (
        !isOfflineDraftObservation ? (
            <ObservationFormOwner
                observationId={observationId}
                locFieldValue={fetchObservationState.value?.ownerLocFieldValue}
                owner={observationOwner}
                setOwner={setObservationOwner}
            />
        ) : (
            <ObservationFormOwner
                observationId={observationId}
                isOfflineDraft
                owner={observationOwner}
            />
        )
    ) : null;

    const reviewSection = observationId && !isOfflineDraftObservation && (
        <ShowIfManagerOrLoc
            locFieldValue={fetchObservationState.value?.ownerLocFieldValue}
        >
            <div
                className='observation__section'
                style={{ paddingTop: '26px' }}
            >
                <Space>
                    <Form.Item name='trusted' valuePropName='checked'>
                        <Checkbox>Mark as Reviewed</Checkbox>
                    </Form.Item>
                </Space>
            </div>
        </ShowIfManagerOrLoc>
    );

    const commentsSection = observationId && !isOfflineDraftObservation && (
        <ShowIfManagerOrLoc
            locFieldValue={fetchObservationState.value?.ownerLocFieldValue}
        >
            <div className='observation__section'>
                <Text className='observation__section--title'>Comments</Text>
                <ObservationFormComments
                    id={observationId}
                    model={models.OBSERVATION}
                />
            </div>
        </ShowIfManagerOrLoc>
    );

    const handleDeleteClick = () => setDeleteModalIsOpen(true);

    const handleDeleteConfirm = () => {
        setDeleteModalIsOpen(false);
        dispatch(deleteObservation(fetchObservationState.value, useStations));
    };

    const handleDeleteCancel = () => setDeleteModalIsOpen(false);

    const deleteModal = (
        <Modal
            open={deleteModalIsOpen}
            title='Delete observation'
            footer={[
                <Button key='cancel' onClick={handleDeleteCancel}>
                    Cancel
                </Button>,
                <Button
                    key='delete'
                    type='danger'
                    onClick={handleDeleteConfirm}
                >
                    Delete
                </Button>,
            ]}
            onOk={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
        >
            Are you sure that you want to permanently delete this observation.
            This cannot be undone.
        </Modal>
    );

    const shouldAllowMediaTypeUpload = (
        mediaTypeAllowedForProject,
        uploadedMediaArray
    ) => {
        const mediaTypeExist = uploadedMediaArray.length > 0;
        const userIsEditor = isAdmin || isOwner;
        // We show the media type's UI if mediaType exist even if the ability to add mediaType
        // has been disabled on the schema so that an owner or manager can moderate
        // any existing mediaType.
        const shouldShowMediaTypeUploadSection =
            (mediaTypeAllowedForProject || (userIsEditor && mediaTypeExist)) &&
            !isOffline &&
            !isOfflineDraftObservation;

        return shouldShowMediaTypeUploadSection;
    };

    const photosAllowedForProject = schema?.observation?.allowPhotos;
    const shouldShowPhotoUploadSection = shouldAllowMediaTypeUpload(
        photosAllowedForProject,
        uploadedPhotos
    );
    const photoUploadSection = shouldShowPhotoUploadSection && (
        <ObservationFormSection
            label='Photos'
            key={'Photos' + formIterationCounter}
        >
            <MediaUploadList
                type={mediaTypes.photo}
                items={uploadedPhotos}
                onChange={setUploadedPhotos}
                newMediaAllowed={photosAllowedForProject}
                onStatusChange={setPhotoUploadStatus}
            />
        </ObservationFormSection>
    );

    const customHandleMediaUpload = (mediaType, postDatabaseUploadCallback) => {
        return async ({ file, data, onSuccess, onError }) => {
            try {
                // Fetch a pre-signed s3 upload URL to carry out direct file upload from the client.
                const filenameFormData = new FormData();
                filenameFormData.set('filename', data.label);
                const signedS3UploadInfo = await apiRequest.post(
                    getSignedS3MediaUrl,
                    filenameFormData
                );

                if (signedS3UploadInfo.status !== 200) {
                    onError('Failed to get signed s3 url');
                }
                const signedS3UploadInfoPayload = signedS3UploadInfo.data;
                const url = signedS3UploadInfoPayload.result.data.url;

                // Upload file to s3
                const formFields = {
                    ...signedS3UploadInfoPayload.result.data.fields,
                    file,
                };
                const signedS3Data = new FormData();
                forEach(formFields, (value, key) =>
                    signedS3Data.set(key, value)
                );
                await apiRequest.post(url, signedS3Data);

                // Finally, update the database with the media file
                const formData = new FormData();
                forEach(data, (value, key) => formData.set(key, value));
                formData.set('type', mediaType);
                formData.set('url', signedS3UploadInfoPayload.result.url);
                const addFileToMediaTable = await apiRequest.post(
                    mediaUrl,
                    formData
                );
                if (addFileToMediaTable.data.status !== 200) {
                    throw new Error(addFileToMediaTable.data);
                }
                // Update or add file to state
                postDatabaseUploadCallback(addFileToMediaTable.data.result);

                // Terminate promise
                onSuccess();
            } catch (error) {
                console.error(error);
                onError();
            }
        };
    };

    const updateDocumentsWithFileIds = fileObject =>
        setUploadedDocumentsWithFileIds(
            update(uploadedDocumentsWithFileIds, {
                $push: [normalizeFileObject(fileObject)],
            })
        );

    const documentsAllowedForProject = schema?.observation?.allowDocuments;
    const shouldShowDocUploadSection = shouldAllowMediaTypeUpload(
        documentsAllowedForProject,
        uploadedDocuments
    );

    const documentsUploadSection = shouldShowDocUploadSection && (
        <ObservationFormSection
            label='Documents'
            key={'Documents' + formIterationCounter}
        >
            <Form.Item name={'Documents'}>
                <MediaUploadList
                    type={mediaTypes.document}
                    items={uploadedDocuments}
                    onChange={setUploadedDocuments}
                    customRequest={customHandleMediaUpload(
                        mediaTypes.document,
                        updateDocumentsWithFileIds
                    )}
                    newMediaAllowed={documentsAllowedForProject}
                    onStatusChange={setDocumentUploadStatus}
                />
            </Form.Item>
        </ObservationFormSection>
    );

    const updateAudioWithFileIds = fileObject =>
        setUploadedAudioWithFileIds(
            update(uploadedAudioWithFileIds, {
                $push: [normalizeFileObject(fileObject)],
            })
        );

    const updateVideosWithFileIds = fileObject =>
        setUploadedVideosWithFileIds(
            update(uploadedVideosWithFileIds, {
                $push: [normalizeFileObject(fileObject)],
            })
        );

    const audioVideoAllowedForProject = schema?.observation?.allowAudioVideo;
    const shouldShowAudioVideoUploadSection = shouldAllowMediaTypeUpload(
        audioVideoAllowedForProject,
        uploadedDocuments
    );
    const audioVideoUploadSection = shouldShowAudioVideoUploadSection && (
        <>
            <ObservationFormSection
                label='Audio'
                key={'Audio' + formIterationCounter}
            >
                <Form.Item name={'Audio'}>
                    <MediaUploadList
                        type={mediaTypes.audio}
                        items={uploadedAudio}
                        onChange={setUploadedAudio}
                        customRequest={customHandleMediaUpload(
                            mediaTypes.audio,
                            updateAudioWithFileIds
                        )}
                        newMediaAllowed={audioVideoAllowedForProject}
                        onStatusChange={setAudioUploadStatus}
                    />
                </Form.Item>
            </ObservationFormSection>
            <ObservationFormSection
                label='Video'
                key={'Video' + formIterationCounter}
            >
                <Form.Item name={'Video'}>
                    <MediaUploadList
                        type={mediaTypes.video}
                        items={uploadedVideos}
                        onChange={setUploadedVideos}
                        customRequest={customHandleMediaUpload(
                            mediaTypes.video,
                            updateVideosWithFileIds
                        )}
                        newMediaAllowed={audioVideoAllowedForProject}
                        onStatusChange={setVideoUploadStatus}
                    />
                </Form.Item>
            </ObservationFormSection>
        </>
    );

    const mediaAllowedForProject =
        photosAllowedForProject ||
        documentsAllowedForProject ||
        audioVideoAllowedForProject;

    return (
        <>
            {deleteModal}
            <Form
                form={form}
                className='observation-form'
                onFinish={handleFinish}
                initialValues={flattenedObservation}
                onFinishFailed={handleFinishFailed}
                layout='vertical'
                onFieldsChange={changed => {
                    changed.forEach(c => {
                        if (
                            c.name.length > 1 &&
                            c.name[1] === 'unit' &&
                            c.touched &&
                            !c.validating
                        ) {
                            form.validateFields([[c.name[0], 'value']]);
                        }
                    });
                }}
                scrollToFirstError={{ block: 'center' }}
            >
                {reviewSection}
                {metadataSection}
                {ownerSection}
                {commentsSection}
                {locationSection}
                {observationFields}
                {photoUploadSection}
                {documentsUploadSection}
                {audioVideoUploadSection}
                <Affix offsetBottom={0}>
                    <div
                        className='observation-form__footer'
                        key='observation-form-footer'
                    >
                        <Form.Item name={SHOULD_ADD_ANOTHER} noStyle>
                            <Input type='hidden' />
                        </Form.Item>
                        <Form.Item>
                            <Space>
                                <Button onClick={() => history.goBack()}>
                                    Cancel
                                </Button>
                                {observationId && (
                                    <ShowIfManagerOrLoc
                                        locFieldValue={
                                            fetchObservationState.value
                                                ?.ownerLocFieldValue
                                        }
                                        alsoShowIf={isOfflineDraftObservation}
                                    >
                                        <Button
                                            type='danger'
                                            onClick={handleDeleteClick}
                                            disabled={
                                                deleteObservationState.loading ||
                                                saveObservationsState.loading
                                            }
                                            loading={
                                                deleteObservationState.loading
                                            }
                                        >
                                            Delete
                                        </Button>
                                    </ShowIfManagerOrLoc>
                                )}
                                <Button
                                    type='primary'
                                    onClick={handleSaveClick}
                                    disabled={
                                        saveObservationsState.loading ||
                                        deleteObservationState.loading ||
                                        uploadInProgress
                                    }
                                    loading={
                                        form.getFieldValue(
                                            SHOULD_ADD_ANOTHER
                                        ) && saveObservationsState.loading
                                    }
                                >
                                    Save observation
                                </Button>
                                {saveAndAddAnotherButton}
                            </Space>
                        </Form.Item>
                    </div>
                </Affix>
            </Form>
            <RequiredFormLegend />
            {isOffline && (
                <div className='offline-interior-banner'>
                    This observation will be saved on your device.{' '}
                    {mediaAllowedForProject
                        ? 'Media cannot be uploaded when offline. You will need to upload the observation and any supporting media '
                        : 'You will need to upload it '}
                    once you're back online.
                </div>
            )}
            {isOfflineDraftObservation && mediaAllowedForProject && (
                <div className='offline-interior-banner'>
                    Media cannot be uploaded to draft observations. You will
                    need to upload the observation before adding any supporting
                    media.
                </div>
            )}
        </>
    );
}

function mapStateToProps({
    project,
    schema: schemas,
    auth: { user },
    dispatch,
    stations: { data: stations, fetching: isFetchingStations },
    ui: { isOffline, observationEditLocation },
    observations: { delete: deleteObservationState },
}) {
    return {
        project,
        user,
        schemas,
        stations,
        isFetchingStations,
        isOffline,
        observationEditLocation,
        deleteObservationState,
        dispatch,
    };
}

export default connect(mapStateToProps)(ObservationForm);
