import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';

import { Alert, Form, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ObservationFormStationMap from './ObservationFormStationMap';
import { defaultExtentToViewport } from '../util/mapHelper';

const COORDINATE_STEP = 0.0001;
const COORDINATE_PRECISION = 5;

export default function ObservationFormLocation({
    project,
    editStation,
    basemap,
    isOffline,
    form,
    isGeoLocationActive,
}) {
    const initialStationPoint = editStation
        ? {
              latitude: editStation.geometry.y,
              longitude: editStation.geometry.x,
          }
        : defaultExtentToViewport(project.data.defaultExtent);

    const [stationPoint, setStationPoint] = useState(initialStationPoint);

    const [desiredCenter, setDesiredCenter] = useState(initialStationPoint);

    const fixPrecision = ({ latitude, longitude }) => ({
        latitude: parseFloat(latitude.toFixed(COORDINATE_PRECISION)),
        longitude: parseFloat(longitude.toFixed(COORDINATE_PRECISION)),
    });

    const makeLatLngChangeHandler = (e, name) => {
        const limits = {
            longitude: { min: -180, max: 180 },
            // Map doesn't allow scrolling past 84.23195 or -84.23195
            // Map also throws an error when manually set to -90
            latitude: { min: -84, max: 84 },
        };
        if (!limits[name]) return;

        let newValue = parseFloat(e.currentTarget.value);
        const stationPointCopy = stationPoint;

        if (newValue !== 0 && !newValue) {
            setStationPoint(update(stationPoint, { $set: stationPointCopy }));
            return;
        }

        if (newValue < limits[name].min) {
            newValue = limits[name].min;
        } else if (newValue > limits[name].max) {
            newValue = limits[name].max;
        }
        setStationPoint(
            update(stationPoint, {
                [name]: { $set: newValue },
            })
        );
        setDesiredCenter(
            update(desiredCenter, {
                [name]: { $set: newValue },
            })
        );
        form.setFieldsValue({ [name]: newValue });
    };

    const resetFields = editStation => {
        if (editStation) {
            // Reset fields to their original values
            setStationPoint({
                latitude: editStation.geometry.y,
                longitude: editStation.geometry.x,
            });
        } else {
            // Reset fields to empty for new station forms
            setStationPoint(
                defaultExtentToViewport(project.data.defaultExtent)
            );
        }
    };

    // Initialize form fields on every load
    useEffect(() => {
        resetFields(editStation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editStation]);

    form.setFieldsValue(fixPrecision(stationPoint));

    return (
        <>
            {isOffline ? (
                <div className='offline-interior-banner'>
                    <div className='offline-interior-banner__text'>
                        <b>This map is not available offline</b>
                        <br /> You can enter coordinates manually. You can also
                        update this information later.
                    </div>
                </div>
            ) : (
                <>
                    {isGeoLocationActive && (
                        <Alert
                            style={{ marginBottom: '10px' }}
                            message='Drag the map or enter a latitude and longitude to position the circle at the location'
                            type='info'
                            showIcon
                            icon={<FontAwesomeIcon icon={['far', 'map']} />}
                        />
                    )}
                    <ObservationFormStationMap
                        desiredLat={desiredCenter.latitude}
                        desiredLng={desiredCenter.longitude}
                        onCenterChange={setStationPoint}
                        project={project}
                        shouldShowCenterPoint
                        basemap={basemap}
                        isGeoLocationActive={isGeoLocationActive}
                    />
                </>
            )}
            <Space
                className={'custom-number-input'}
                style={{ marginTop: '10px' }}
            >
                <Form.Item key='latitude' name='latitude' label='Latitude'>
                    <input
                        className='ant-input-number-input custom-number-input'
                        type='number'
                        required={true}
                        min={-90.0}
                        max={90.0}
                        step={COORDINATE_STEP}
                        onBlur={e => makeLatLngChangeHandler(e, 'latitude')}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item key='longitude' name='longitude' label='Longitude'>
                    <input
                        className='ant-input-number-input custom-number-input'
                        type='number'
                        required={true}
                        min={-180.0}
                        max={180.0}
                        precision={5}
                        step={COORDINATE_STEP}
                        onBlur={e => makeLatLngChangeHandler(e, 'longitude')}
                        style={{ width: '100%' }}
                        value={stationPoint.longitude}
                    />
                </Form.Item>
            </Space>
        </>
    );
}
