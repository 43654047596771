import apiRequest from '../util/apiRequest';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { Link, useHistory } from 'react-router-dom';
import { Form, Button, Spin, Alert, Typography, Divider } from 'antd';

import { registerUser } from '../actions/auth';
import { userUrl, baseRegistrationFormSchema } from '../util/constants';

import SchemaFormFields from './SchemaFormFields';
import RequiredFormLegend from './RequiredFormLegend';
import { formValuesToUserRequestPayload } from '../util/profileHelper';

const { Paragraph } = Typography;

function AuthRegistrationForm() {
    const dispatch = useDispatch();
    const history = useHistory();
    const projectUserSchema = useSelector(
        state => state.project?.data?.userSchema
    );
    const isFetchingProject = useSelector(state => state.project.fetching);
    const hasErroredFetchingProject = useSelector(state => state.project.error);
    const [alertMessage, setAlertMessage] = useState(null);

    const formSchema = [
        ...baseRegistrationFormSchema,
        ...(projectUserSchema?.folders?.[0]?.fields ?? []),
    ];

    const [registerRequestState, registerRequest] = useAsyncFn(
        async formValues => {
            const fieldNames = formSchema.map(field => field.name);
            const response = await apiRequest.post(
                userUrl,
                formValuesToUserRequestPayload(fieldNames, formValues)
            );
            const payload = response.data;

            if (payload.status === 200 && payload.result) {
                dispatch(registerUser(payload.result));
                history.push('/register/complete');
            } else {
                const error = payload.error ? `: ${payload.error}` : '';
                setAlertMessage(
                    `There was an error registering for an account${error}`
                );
            }

            return payload;
        },
        [formSchema]
    );

    // Scroll to top if alert message changes
    useEffect(() => {
        if (alertMessage) {
            window.scroll({ top: 0, behavior: 'smooth' });
        }
    }, [alertMessage]);

    const handleFinish = values => {
        setAlertMessage(null);
        registerRequest(values);
    };

    const formFields = !hasErroredFetchingProject && (
        <SchemaFormFields fields={formSchema} indicateRequired={true} />
    );

    const alert = alertMessage && (
        <Alert
            message={alertMessage}
            type='info'
            showIcon
            closable
            style={{ margin: '10px 0' }}
        />
    );

    const registrationForm = (
        <>
            {alert}
            <Form
                onFinish={handleFinish}
                layout='vertical'
                className='auth-form'
            >
                {formFields}
                <Form.Item className='auth-form__buttongroup'>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='auth-form-button'
                        loading={registerRequestState.loading}
                    >
                        Sign up
                    </Button>
                    <Link to='login' className='ant-btn'>
                        Member already? Sign in
                    </Link>
                </Form.Item>
                <RequiredFormLegend />
                <Divider />
                <Paragraph>
                    By joining, you agree to our{' '}
                    <a href='http://www.fieldscope.org/terms-of-use/'>
                        Terms of Service
                    </a>{' '}
                    and{' '}
                    <a href='http://www.fieldscope.org/privacy-policy/'>
                        Privacy Policy
                    </a>
                </Paragraph>
            </Form>
        </>
    );

    const spinner = <Spin />;

    return <>{isFetchingProject ? spinner : registrationForm}</>;
}

export default AuthRegistrationForm;
